.speech-bubble {
  background: #eeeeee;
  padding: 1.6em 3em;
  border-radius: 0.8em;
  box-shadow: 2px 3px 2px 0 rgba(34, 36, 38, 0.15);
  transition: opacity 300ms linear;
}

.speech-bubble:after {
  content: '';
  position: absolute;
  left: 1.65em;
  top: 10em;
  width: 0;
  height: 0;
  border: 40px solid transparent;
  border-right-color: #eeeeee;
  border-left: 0;
  border-bottom: 0;
  margin-top: -20px;
  margin-left: -40px;
  box-shadow: 0 3px 2px -2px rgba(34, 36, 38, 0.15);
}

@media (max-width: 767px) {
  .speech-bubble {
    margin-top: 2em;
    padding: 2em;
  }

  /*noinspection CssOptimizeSimilarProperties*/
  .speech-bubble:after {
    top: 3em;
    left: 45%;
    width: 0;
    height: 0;
    border: 40px solid transparent;
    border-bottom-color: #eeeeee;
    border-top: 0;
    border-right: 0;
    margin-top: -40px;
    margin-left: -20px;
    box-shadow: 2px 0 2px -2px rgba(34, 36, 38, 0.15);
  }
}
