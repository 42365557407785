body {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
  width: 100%;
}
