.presentation {
  padding: 4em 2em 2em;
}

.presentation-header {
  text-align: left;
  font-size: 3.6em;
  font-weight: 600;
  margin-bottom: 0.2em;
}

.social-links {
  float: right;
  margin-top: -5px;
}

.social-links > .social-icon {
  height: 45px !important;
  width: 45px !important;
}

.social-links > .social-icon:not(:first-child) {
  margin-left: 8px !important;
}

.presentation .options button {
  text-align: left;
}

.presentation-text p {
  margin: 0 0 1em;
  font-size: 1.6em;
  font-weight: 400;
  transition: color 300ms linear;
}

.presentation-text img {
  transition: opacity 300ms linear;
}

.presentation-text.tldr p {
  color: rgba(0, 0, 0, 0.15);
}

.presentation-text.tldr img {
  opacity: 0.15;
}

.presentation .options .tldr-button.button {
  min-width: 210px;
  transition: background-color 300ms linear;
}

.presentation .options .tldr-button.button:hover {
  background-color: #cdcecf !important;
}

.presentation .options .tldr-button.active.button {
  background-color: #00a79d !important;
}

.presentation .options .tldr-button.active.button:hover {
  background-color: #009389 !important;
}

@media (max-width: 993px) {
  .presentation {
    padding-top: 2em;
  }

  .social-links {
    width: 100%;
    margin-bottom: 0.2em;
    float: none;
  }
}

@media (max-width: 645px) {
  .presentation {
    padding: 2em 0.5em;
  }

  .presentation-header {
    font-size: 3.2em;
  }

  .presentation-text {
    font-size: 0.9em;
  }
}
