.avatar {
  width: 235px;
  height: 300px;
  vertical-align: middle;
  display: block;
  margin: 0 auto;
}

.avatar .hair.light {
  fill: #8b5e3c;
}

.avatar .hair.dark {
  fill: #754c29;
}

.avatar .hair.streak {
  fill: #a97c50;
}

.avatar .skin.light {
  fill: #f2c09a;
}

.avatar .skin.dark {
  fill: #e9937c;
}

.avatar .eyes {
  fill: #143441;
  cursor: pointer;
}

.avatar .nose {
  fill: #e3646a;
  cursor: pointer;
}

.avatar .throat {
  fill: #7b072d;
}

.avatar .tongue {
  fill: #da1c4b;
}

.avatar .teeth {
  fill: #f7f8eb;
}

.avatar .shirt {
  fill: #00a79d;
}
